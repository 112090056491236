<template>
  <list-params
    class="notifications-list-params"
    v-model="form"
    :initial-value="value"
    @form-apply="$emit('input', form)"
  >
    <template slot="form-rows">
      <list-params-sort
        class="notifications-list-params__row"
        v-model="form.sort"
        :options="sortOptions"
        :default-value="defaultSort"
      />
    </template>
  </list-params>
</template>

<script>
import {
  NOTIFICATIONS_QUERY_SORTS,
  NOTIFICATIONS_TAB_DEFAULT_SORT,
} from 'Constants/notificationsUrlParams'
import {
  ListParams,
  ListParamsSort,
} from 'Common/ListParams'

export default {
  name: 'notifications-list-params',
  components: {
    ListParams,
    ListParamsSort,
  },

  props: {
    value: {
      type: Object,
      required: true,
      validator (value) {
        return typeof value.sort === 'string'
      },
    },
  },

  data () {
    return {
      form: {
        sort: '',
      },
    }
  },

  computed: {
    sortOptions () {
      return [
        {
          value: NOTIFICATIONS_QUERY_SORTS.createdAtDesc,
          label: this.$t('SORT_NEW_FIRST_OPT'),
        },
        {
          value: NOTIFICATIONS_QUERY_SORTS.createdAt,
          label: this.$t('SORT_NEW_LAST_OPT'),
        },
      ]
    },

    defaultSort () {
      return NOTIFICATIONS_TAB_DEFAULT_SORT[this.$route.params.tab]
    },
  },
}
</script>

<i18n>
{
  "en": {
    "SORT_NEW_FIRST_OPT": "New first",
    "SORT_NEW_LAST_OPT": "New last"
  },
  "ka": {
    "SORT_NEW_FIRST_OPT": "ბოლო მომართვები",
    "SORT_NEW_LAST_OPT": "ადრინდელი მომართვები"
  },
  "ru": {
    "SORT_NEW_FIRST_OPT": "Сначала новые",
    "SORT_NEW_LAST_OPT": "Сначала старые"
  },
  "uk": {
    "SORT_NEW_FIRST_OPT": "Спочатку нові",
    "SORT_NEW_LAST_OPT": "Спочатку старі"
  }
}
</i18n>
