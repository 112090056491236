<template>
  <list-grouped
    class="notifications-list"
    :list="notifications"
    :list-total-count="0"
    :group-by-date-field="groupBy"
    :is-loading="isLoading"
    :is-next-loading="isNextLoading"
    :page-size="NOTIFICATIONS_PAGE_LIMIT"
    :load-more="loadMore"
    :item-component="NotificationItem"
    :item-skeleton-component="NotificationItemSkeleton"
    :reset-on-change="$route.params.tab"
    @update-list-ask="$emit('update-list-ask')"
  />
</template>

<script>
import NotificationItem from './NotificationItem'
import NotificationItemSkeleton from './NotificationItemSkeleton'
import ListGrouped from 'Common/ListGrouped'
import { mapGetters, mapActions } from 'vuex'
import { notificationsGetters, notificationsActions } from '../store/types'
import { NOTIFICATIONS_PAGE_LIMIT } from '../constants'

const GROUP_BY_ENUM = {
  createdAt: 'createdAt',
}

export default {
  name: 'notifications-list',

  components: {
    ListGrouped,
  },

  props: {
    groupBy: {
      type: String,
      default: 'createdAt',
      validator (value) {
        return !value || Object.values(GROUP_BY_ENUM).includes(value)
      },
    },
  },

  data () {
    return {
      NotificationItem,
      NotificationItemSkeleton,
      NOTIFICATIONS_PAGE_LIMIT,
    }
  },

  computed: {
    ...mapGetters('ui/notifications', {
      notifications: notificationsGetters.LIST,
      isLoading: notificationsGetters.IS_LOADING,
      isNextLoading: notificationsGetters.IS_NEXT_LOADING,
    }),
  },

  methods: {
    ...mapActions('ui/notifications', {
      loadMore: notificationsActions.LOAD_MORE,
    }),
  },
}
</script>

<style lang="scss" scoped>
.notifications-list {
  --list-padding-side: 3em;
}
</style>
