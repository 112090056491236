export const notificationsActions = {
  LOAD_NOTIFICATIONS: 'LOAD_NOTIFICATIONS',
  LOAD_MORE: 'LOAD_MORE',
  UNSHIFT_NOTIFICATIONS: 'UNSHIFT_NOTIFICATIONS',
}

export const notificationsMutations = {
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_IS_NEXT_LOADING: 'SET_IS_NEXT_LOADING',
  SET_LIST: 'SET_LIST',
  PUSH_LIST: 'PUSH_LIST',
  UNSHIFT_LIST: 'UNSHIFT_LIST',
  SET_FETCH_NEXT: 'SET_FETCH_NEXT',
}

export const notificationsGetters = {
  IS_LOADING: 'IS_LOADING',
  IS_NEXT_LOADING: 'IS_NEXT_LOADING',
  LIST: 'LIST',
  FETCH_NEXT: 'FETCH_NEXT',
}
