<template>
  <div class="notification-item">
    <router-link
      class="notification-item__row-link notifications-cmn__row"
      v-bind="buildNotificationRedirectionRouteParams(item)"
    >
      <div class="notification-item__cell">
        <template v-if="item.cargo.lot">
          <cell-deed-lot
            class="notification-item__cell-item"
            :lot="item.cargo.lot"
          />
        </template>

        <cell-deed
          class="notification-item__cell-item"
          :notification="item"
        />
      </div>

      <span
        class="notification-item__cell notification-item__cell_end"
        :class="'cells-cmn__cell'"
      >
        <div class="notification-item__btn-decor">
          <ui-icon
            class="notification-item__btn-decor-ico"
            icon="arrow-right"
          />
        </div>
      </span>
    </router-link>
  </div>
</template>

<script>
import { Notification } from 'Models/Notification'
import { CellDeed, CellDeedLot } from 'Common/cells'
import { UiIcon } from '@shelf.network/ui-kit'
import { buildNotificationRedirectionRouteParams } from 'Utils/buildNotificationRedirectionRouteParams'

export default {
  name: 'notification-item',

  components: {
    CellDeed,
    CellDeedLot,
    UiIcon,
  },

  props: {
    item: {
      type: Notification,
      required: true,
    },
  },

  methods: {
    buildNotificationRedirectionRouteParams,
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/notifications";
@import "@/common/cells/cells.scss";

.notification-item {
  padding: 2.1em 0;
  border-bottom: 1px solid rgba($color-grey, 0.5);

  &__cell {
    @extend .notifications-cmn__row;

    gap: 3em;
    justify-content: start;

    &_end {
      justify-self: end;
    }
  }

  &__btn-decor {
    background: $color-light-grey;
    border: none;
    text-decoration: none;
    color: $color-ui-default;
    width: 3.55em;
    height: 3.55em;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 180ms;

    &-ico {
      display: block;
      color: $color-ui-secondary;
      font-size: 1.2em;
      transition: all 180ms;
      padding-top: 0.15em;
    }
  }

  &__row-link {
    text-decoration: none;

    &:hover {
      position: relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: -0.75em;
        bottom: -0.75em;
        left: -1.25em;
        right: -1.25em;
        background: mix($color-ui-grey-blue3, $color-ui-default, 96);
        pointer-events: none;
        z-index: z-index(negative);
        border-radius: 1em;
        animation: notification-item__fade-in 180ms;
      }
    }
  }
}

@keyframes notification-item__fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
