<template>
  <div class="notifications">
    <page-subnav class="notifications__subnav">
      <notifications-list-params
        class="notifications__subnav-action"
        :value="{ sort: $route.query.sort || '' }"
        @input="onListParamsChange"
      />

      <template slot="right">
        <router-link
          class="notifications__tab"
          active-class="notifications__tab_active"
          :to="{
            params: { tab: NOTIFICATIONS_URL_PARAM_TABS.urgent },
            query: $route.query,
          }"
        >
          <span class="notifications__tab-txt">
            {{ $tc('URGENT_TAB', urgentCount, { count: urgentCount }) }}
          </span>
          <template v-if="urgentCount > 0">
            <ui-icon
              icon="circle-full"
              class="notifications__tab-unread-indicator"
            />
          </template>
        </router-link>

        <router-link
          class="notifications__tab"
          active-class="notifications__tab_active"
          :to="{
            params: { tab: NOTIFICATIONS_URL_PARAM_TABS.info },
            query: $route.query
          }"
        >
          <span class="notifications__tab-txt">
            {{ $tc('INFO_TAB', infoCount, { count: infoCount }) }}
          </span>
          <template v-if="infoCount > 0">
            <ui-icon
              icon="circle-full"
              class="notifications__tab-unread-indicator"
            />
          </template>
        </router-link>
      </template>
    </page-subnav>

    <notifications-list
      class="notifications__list"
      group-by="createdAt"
      @update-list-ask="loadNotifications"
    />
  </div>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import PageSubnav from 'Common/PageSubnav'

import NotificationsList from './components/NotificationsList'
import NotificationsListParams from './components/NotificationsListParams'

import { mapActions, mapGetters } from 'vuex'
import { notificationsActions } from './store/types'
import { bellGetters } from 'Store/entities/Bell/types'

import {
  NOTIFICATIONS_URL_PARAM_TABS,
  NOTIFICATIONS_TAB_DEFAULT_SORT,
  NOTIFICATIONS_QUERY_SORTS,
} from 'Constants/notificationsUrlParams'
import { URGENT_TOPICS, INFO_TOPICS } from 'Constants/notificationTopics'

import { notificationsClient, NOTIFICATIONS_CLIENT_EVENTS } from 'Services/notificationsClient'
import { Notification } from 'Models/Notification'

import { showError } from 'Utils/notifications'
import isEqual from 'lodash/isEqual'

const SORT_TO_ORDER_MAP = {
  [NOTIFICATIONS_QUERY_SORTS.createdAt]: 'asc',
  [NOTIFICATIONS_QUERY_SORTS.createdAtDesc]: 'desc',
}

export default {
  name: 'notifications',

  components: {
    PageSubnav,
    NotificationsList,
    NotificationsListParams,
    UiIcon,
  },

  data () {
    return {
      NOTIFICATIONS_URL_PARAM_TABS,
    }
  },

  computed: {
    ...mapGetters('entities/bell', {
      urgentCount: bellGetters.URGENT_UNREAD_COUNT,
      infoCount: bellGetters.INFO_UNREAD_COUNT,
    }),

    tabDefaultSort () {
      return NOTIFICATIONS_TAB_DEFAULT_SORT[this.$route.params.tab]
    },

    tabTopicsFlatten () {
      const tabToTopicsMap = {
        [NOTIFICATIONS_URL_PARAM_TABS.urgent]: URGENT_TOPICS,
        [NOTIFICATIONS_URL_PARAM_TABS.info]: INFO_TOPICS,
      }
      return tabToTopicsMap[this.$route.params.tab].join()
    },

    metaTitle () {
      const tabTitles = {
        [NOTIFICATIONS_URL_PARAM_TABS.urgent]: this.$t('META_TITLE_URGENT'),
        [NOTIFICATIONS_URL_PARAM_TABS.info]: this.$t('META_TITLE_INFO'),
      }
      return tabTitles[this.$route.params.tab]
    },
  },

  watch: {
    '$route.params.tab': {
      immediate: true,
      handler (tab, oldTab) {
        if (isEqual(tab, oldTab)) return
        this.onListParamsChange(this.$route.query)
      }
    },
  },

  created () {
    notificationsClient.on(
      NOTIFICATIONS_CLIENT_EVENTS.NOTIFICATION,
      this.onNotificationEvent
    )
  },

  beforeDestroy () {
    notificationsClient.off(
      NOTIFICATIONS_CLIENT_EVENTS.NOTIFICATION,
      this.onNotificationEvent
    )
  },

  methods: {
    ...mapActions('ui/notifications', {
      loadNotificationsAction: notificationsActions.LOAD_NOTIFICATIONS,
      unshiftNotifications: notificationsActions.UNSHIFT_NOTIFICATIONS,
    }),

    onNotificationEvent (data) {
      const notification = new Notification(data)
      if (notification.isRead) return

      const tab = this.$route.params.tab
      const isUrgent = tab === NOTIFICATIONS_URL_PARAM_TABS.urgent &&
        URGENT_TOPICS.includes(notification.topic)
      const isInfo = tab === NOTIFICATIONS_URL_PARAM_TABS.info &&
        INFO_TOPICS.includes(notification.topic)
      if (!isUrgent && !isInfo) return

      this.unshiftNotifications(data)
    },

    onListParamsChange (input = {}) {
      const query = { ...this.$route.query }

      query.sort = input.sort === this.tabDefaultSort
        ? undefined
        : input.sort || undefined

      if (!isEqual(query, this.$route.query)) {
        this.$router.push({ query })
      }

      this.loadNotifications()
    },

    async loadNotifications () {
      const query = this.$route.query
      const payload = {
        page: {},
        filter: {},
      }

      payload.page.order = SORT_TO_ORDER_MAP[query.sort || this.tabDefaultSort]
      payload.filter.topic = this.tabTopicsFlatten

      try {
        await this.loadNotificationsAction(payload)
      } catch (error) {
        if (!error.isCanceled) {
          showError(this.$t('LIST_FETCH_FAILED_NOTIFY'))
          console.error(error)
        }
      }
    },
  },

  metaInfo () {
    return {
      title: this.metaTitle,
      titleTemplate: `%s | ${this.$t('COMMON.APP_TITLE')}`,
    }
  }
}
</script>

<style lang="scss" scoped>
.notifications {
  display: flex;
  flex-direction: column;
  flex: 1;

  // override vue-notification’s ".notifications" styles
  position: unset;
  z-index: unset;

  &__tab {
    position: relative;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    text-decoration: none;
    margin: 0 1.4em;
    color: $color-ui-secondary;
    font-weight: 500;
    letter-spacing: 0.03em;

    &_active {
      color: $color-dark;
      box-shadow: 0 -1px 0 $color-dark inset !important;
    }

    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
    }

    &-unread-indicator {
      color: $color-sys-warning;
      transform: translate(0, -0.5em);
      display: inline-block;
      font-size: 0.8em;
      width: 0;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "URGENT_TAB": "NEEDS ACTION | NEEDS ACTION ({count}) | NEEDS ACTION ({count})",
    "INFO_TAB": "INFORMATIVE | INFORMATIVE ({count}) | INFORMATIVE ({count})",
    "META_TITLE_URGENT": "Needs action",
    "META_TITLE_INFO": "Informative",
    "LIST_FETCH_FAILED_NOTIFY": "Cannot load the notifications list. Please try again later or contact the system owner."
  },
  "ka": {
    "URGENT_TAB": "ᲓᲐᲡᲐᲓᲐᲡᲢᲣᲠᲔᲑᲔᲚᲘ | ᲓᲐᲡᲐᲓᲐᲡᲢᲣᲠᲔᲑᲔᲚᲘ ({count}) | ᲓᲐᲡᲐᲓᲐᲡᲢᲣᲠᲔᲑᲔᲚᲘ ({count})",
    "INFO_TAB": "ᲘᲜᲤᲝᲠᲛᲐᲪᲘᲣᲚᲘ | ᲘᲜᲤᲝᲠᲛᲐᲪᲘᲣᲚᲘ ({count}) | ᲘᲜᲤᲝᲠᲛᲐᲪᲘᲣᲚᲘ ({count})",
    "META_TITLE_URGENT": "დასადასტურებელი",
    "META_TITLE_INFO": "ინფორმაციული",
    "LIST_FETCH_FAILED_NOTIFY": "შეტყობენებების სია ვერ ჩაირტვირთა. დაუკავშირდით ადმინისტრატორს"
  },
  "ru": {
    "URGENT_TAB": "ТРЕБУЕТСЯ ДЕЙСТВИЕ | ТРЕБУЕТСЯ ДЕЙСТВИЕ ({count}) | ТРЕБУЕТСЯ ДЕЙСТВИЕ ({count})",
    "INFO_TAB": "ИНФОРМАЦИОННЫЕ | ИНФОРМАЦИОННЫЕ ({count}) | ИНФОРМАЦИОННЫЕ ({count})",
    "META_TITLE_URGENT": "Требуется действие",
    "META_TITLE_INFO": "Информационные",
    "LIST_FETCH_FAILED_NOTIFY": "Не удалось загрузить список оповещений. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "URGENT_TAB": "ПОТРІБНА ДІЯ | ПОТРІБНА ДІЯ ({count}) | ПОТРІБНА ДІЯ ({count})",
    "INFO_TAB": "ІНФОРМАЦІЙНІ | ІНФОРМАЦІЙНІ ({count}) | ІНФОРМАЦІЙНІ ({count})",
    "META_TITLE_URGENT": "Потрібна дія",
    "META_TITLE_INFO": "Інформаційні",
    "LIST_FETCH_FAILED_NOTIFY": "Не вдалось завантажити список сповіщень. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
